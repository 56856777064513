



































































































import Vue from "vue";
import Auth from "@/lib/Auth";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import { Alert } from "@/lib/Alert";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import Swal from "sweetalert2";
import VueClipboard from "vue-clipboard2";

Vue.use(VueClipboard);
export default Vue.extend({
  name: "meuQRCode",

  data() {
    return {
      sheet: false,
      PixData: [],
      loadingData: true,

      lang: navigator.language,
      errors: [],
      moment: moment,
      isMobile: false,
      breadcrumbs: [
        {
          text: "Início",
          disabled: false,
          href: "/pix",
        },
        {
          text: "Meu Qr Code",
          disabled: true,
          href: "/pix/meu-qr-code",
        },
      ],
      permissoes: {
        perm_pix_recebidos: false,
        perm_pix_enviados: false,
        perm_qrcode_static: false,
      },
    };
  },
  created() {
    this.permissoes = Auth.getUserPermissoes();
    if (!this.permissoes.perm_qrcode_static) {
      this.$router.go(-1);
    }
    this.isMobile = this.detectMobile();
    this.moduloInfo();
  },

  methods: {
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    moduloInfo() {
      const id = this.$route.params.id;
      Overlay.show();

      this.loadingData = true;
      Api.get("/v2/colaboradores/financeiro/adicionar/pix/meu-qr-code")
        .then((response) => {
          this.PixData = response.data.body;
          this.loadingData = false;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            //exe
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    doCopy(str: string) {
      this.$copyText(str).then(function (e) {
        Snackbar.show("Copiado com sucesso!");
      });
    },

    onCopy() {
      // console.log(str);

      Snackbar.show("Copiado com sucesso!");
    },
    onCopyError() {
      // console.log(str);

      Snackbar.show("Erro ao copiar para área de transferência", "error");
    },
  },
});
