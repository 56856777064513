





















































































































import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import { Alert } from "@/lib/Alert";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import Swal from "sweetalert2";
import { TableOptions } from "@/models/TableOptions";

export interface RelatorioPix {
  quantidade?: string;
  valorTotal?: string;
  descricao?: string;
}

export default Vue.extend({
  name: "ResumoMovimentacao",

  data() {
    return {
      menuInitialDate: false,
      menuFinalDate: false,
      menuInitialDateMobile: false,
      ModuloInfo: [],
      loadingData: true,
      search: "",
      loadingDesserts: true,
      lang: navigator.language,
      stats: [],
      errors: [],
      moment: moment,
      isMobile: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,
      options: {} as TableOptions,
      selectedEntryStatusPix: { id: "TODOS", descricao: "Todos" },
      breadcrumbs: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Relatórios",
          disabled: false,
          href: "/relatorios",
        },
      ],
      headers: [
        { text: "Movimento", value: "descricao", sortable: false },
        {
          text: "Quantidade",
          value: "quantidade",
          sortable: false,
          align: "center",
        },
        {
          text: "Total",
          value: "valorTotal",
          sortable: false,
          align: "right",
        },
      ],
      desserts: new Array<RelatorioPix>(),

      totalDesserts: 0,
      timeout: 0,
      startDate: moment().startOf("month").format("YYYY-MM-DD"),
      endDate: moment().endOf("month").format("YYYY-MM-DD"),
      defaultStartDate: moment().startOf("month").format("YYYY-MM-DD"),
      defaultEndDate: moment().endOf("month").format("YYYY-MM-DD"),
      maxDate: moment().format("YYYY-MM-DD"),
    };
  },
  created() {
    this.isMobile = this.detectMobile();
  },

  methods: {
    doDelayedSearch() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        //this is your existing function
        this.loadDatas();
      }, 1000);
    },
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    formataValor(data: number) {
      const valor = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(data);

      return valor.toString().replace("R$", "").trim();
    },
    loadDatas() {
      const id = this.$route.params.id;
      Overlay.show();

      this.loadingData = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.loadingDesserts = true;
      Api.get("/v2/colaboradores/financeiro/resumo", {
        params: {
          startDate: this.startDate,
          endDate: this.endDate,
        },
      })
        .then((response) => {
          this.desserts = response.data.body.rows as RelatorioPix[];
          this.totalDesserts = Number(response.data.body.count.__total);
          this.stats = response.data.body.stats;
          this.loadingDesserts = false;
          this.loadingData = false;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            //exe
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.loadDatas();
      },
    },
    search: {
      handler() {
        this.doDelayedSearch();
      },
    },
  },
});
