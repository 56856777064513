




















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import Auth from "@/lib/Auth";
import Api from "@/services/Api";
import { TableOptions } from "@/models/TableOptions";
import Axios, { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";

export interface IbMovimentos {
  movimento_cod?: string;
  movimento_transacao?: string;
  movimento_pessoa?: string;
  movimento_tipo?: string;
  movimento_data?: string;
  movimento_origem?: number;
  movimento_origem_pessoa?: string;
  movimento_destino_pessoa?: string;
  movimento_valor?: string;
  movimento_historico?: string;
  movimento_ref?: string;
  movimento_icone?: string;
  movimento_id_transferencia_api?: string;
  movimento_computasaldo?: number;
  movimento_canal?: string;
}

export default Vue.extend({
  data() {
    return {
      showSaldo: false,
      loadingBalance: false,
      hasLoadedData: false,
      dialogComprovanteTransferencia: false,
      dialogComprovanteSaque: false,
      dialogComprovantePixEnviado: false,
      dialogComprovantePixRecebido: false,
      dialogComprovanteBoleto: false,
      dialogComprovanteCartao: false,
      dialogTransferSaldo: false,
      dialogFiltrosMobile: false,
      dialogComprovanteRecarga: false,
      panel: [] as number[],
      startPeriod: moment().add(-1, "hours").format("YYYY-MM-DDTHH:mm"),
      endPeriod: moment().format("YYYY-MM-DDT23:59"),
      defaultStartPeriod: moment().add(-1, "hours").format("YYYY-MM-DDTHH:mm"),
      defaultEndPeriod: moment().format("YYYY-MM-DDT23:59"),
      // startDate: moment().add(-15, "days").format("YYYY-MM-DD"),
      startDate: moment().format("YYYY-MM-DD"),
      //defaultStartDate: moment().add(-15, "days").format("YYYY-MM-DD"),
      defaultStartDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      defaultEndDate: moment().format("YYYY-MM-DD"),
      maxDate: moment().format("YYYY-MM-DD"),
      lang: navigator.language,
      btnReloadLoading: false,
      btnDownloadDisabled: true,
      btnDownloadLoading: false,
      moment: moment,
      filtrosMobile: "",
      search: "",
      page: 1,
      errors: [],
      pageCount: 0,
      itemsPerPage: 10,
      totalEntradas: 0,
      totalSaidas: 0,
      saldoCliente: 0,
      somaDaPagina: 0,
      somaTotal: 0,
      menuInitialDate: false,
      pages: 7,
      menuFinalDate: false,
      menuInitialDateMobile: false,
      menuFinalDateMobile: false,
      dadosComprovanteTransferencia: [],
      dadosComprovanteSaque: [],
      dadosComprovanteBoleto: [],
      dadosComprovanteCartao: [],
      dadosComprovanteRecarga: [],
      dadosComprovantePixEnviado: [],
      dadosComprovantePixRecebido: [],
      options: {} as TableOptions,
      toExport: false,
      selectedEntryTipoMovimentos: { id: "T", descricao: "Tudo" },
      tipoMovimentos: [
        { id: "T", descricao: "Tudo" },
        { id: "C", descricao: "Entradas" },
        { id: "D", descricao: "Saídas" },
      ],
      selectedEntryFiltrosMovimentos: { id: "T", descricao: "Tudo" },
      filtrosMovimentos: [
        { id: "T", descricao: "Todos" },
        { id: "35", descricao: "Pix Recebidos" },
        { id: "36", descricao: "Pix Enviados" },
        { id: "37", descricao: "Pix Estornados" },
        { id: "38", descricao: "Tarifa Pix Enviado" },
        { id: "39", descricao: "Tarifa Pix Recebido" },
        { id: "16", descricao: "Tarifa Movimentação" },
        { id: "15", descricao: "Tarifa Retirada Bancária" },
        { id: "6", descricao: "Transferência enviada" },
        { id: "5", descricao: "Transferência recebida" },
        { id: "41", descricao: "Reembolso Pix" },
        { id: "10", descricao: "Retirada para conta bancária" },
      ],

      desserts: {},
      loadingDesserts: false,
      totalDesserts: 0,
      timeout: 0,
      permissoes: { perm_extrato: false, perm_saldo: false },
    };
  },
  created() {
    this.permissoes = Auth.getUserPermissoes();

    if (!this.permissoes.perm_extrato) {
      this.$router.go(-1);
    }

    if (this.permissoes.perm_saldo) {
      this.getBalance();
    }
    //this.loadDatas();
  },
  methods: {
    Comprovante(origem: number, id: string) {
      Overlay.show();
      let pathUri = "";
      if (origem == 6 || origem == 5) {
        pathUri = "comprovantes/transferencias/" + id;
      }
      if (origem == 10) {
        pathUri = "comprovantes/saques/" + id;
      }

      if (origem == 21) {
        pathUri = "comprovantes/boleto/" + id;
      }
      if (origem == 25) {
        pathUri = "comprovantes/cartao/" + id;
      }
      if (origem == 18) {
        pathUri = "comprovantes/recargas/" + id;
      }
      if (origem == 35 || origem == 34) {
        pathUri = "comprovantes/pix/receive/" + id;
      }
      if (origem == 36) {
        pathUri = "comprovantes/pix/sent/" + id;
      }

      Api.get("/v2/colaboradores/" + pathUri)
        .then((response) => {
          if (origem == 6 || origem == 5) {
            this.dadosComprovanteTransferencia = response.data.body;

            this.dialogComprovanteTransferencia = true;
          }

          if (origem == 10) {
            this.dadosComprovanteSaque = response.data.body;
            this.dialogComprovanteSaque = true;
          }

          if (origem == 21) {
            this.dadosComprovanteBoleto = response.data.body;
            this.dialogComprovanteBoleto = true;
          }
          if (origem == 25) {
            this.dadosComprovanteCartao = response.data.body;
            this.dialogComprovanteCartao = true;
          }
          if (origem == 18) {
            this.dadosComprovanteRecarga = response.data.body;
            this.dialogComprovanteRecarga = true;
          }
          if (origem == 36) {
            this.dadosComprovantePixEnviado = response.data.body;
            this.dialogComprovantePixEnviado = true;
          }
          if (origem == 35 || origem == 34) {
            this.dadosComprovantePixRecebido = response.data.body;
            this.dialogComprovantePixRecebido = true;
          }
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;
          Snackbar.show(data.body.error, "error");
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    next(page: number) {
      this.page = page;
      this.loadDatas();
    },
    reloadFilters() {
      this.btnReloadLoading = true;
      this.startPeriod = this.defaultStartPeriod;
      this.endPeriod = this.defaultEndPeriod;
      this.doDelayedSearch();
    },
    doDelayedSearch() {
      this.dialogFiltrosMobile = false;
      this.page = 1;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        //this is your existing function
        this.loadDatas();
      }, 500);
    },
    formataValor(data: number) {
      const valor = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(data);

      return valor.toString().replace("R$", "").trim();
    },
    loadDatas() {
      const intervalo = Math.round(
        (new Date(this.endPeriod).getTime() -
          new Date(this.startPeriod).getTime()) /
          (1000 * 3600 * 24)
      );

      if (intervalo > 31) {
        Snackbar.show(
          "O intervalo de pesquisa não pode ser superior a 31 dias",
          "error"
        );
        return false;
      }
      this.errors = [];
      this.hasLoadedData = true;
      Overlay.show();

      this.loadingDesserts = true;
      Api.get("/v2/colaboradores/movimento", {
        params: {
          startDate: this.startPeriod,
          endDate: this.endPeriod,
          entryType: this.selectedEntryTipoMovimentos.id,
          filterType: this.selectedEntryFiltrosMovimentos.id,
          page: this.page,
          export: this.toExport,
        },
      })
        .then((response) => {
          const data = response.data.body.efetivadas.rows;
          // this gives an object with dates as keys
          const groups = data.reduce((groups: any, rows: any) => {
            const date = rows.data.split(" ")[0];
            if (!groups[date]) {
              groups[date] = [];
            }
            groups[date].push(rows);
            return groups;
          }, {});

          // Edit: to add it in the array format instead
          const groupArrays = Object.keys(groups).map((date) => {
            return {
              date,
              rows: groups[date],
            };
          });

          this.desserts = groupArrays;
          //console.log(this.desserts);
          this.filtrosMobile =
            moment(this.startDate).locale(this.lang).format("DD/MM/yyyy") +
            "-" +
            moment(this.endDate).locale(this.lang).format("DD/MM/yyyy");
          this.totalDesserts = response.data.body.efetivadas.count.__total;
          this.pages = response.data.body.efetivadas.count.pages;
          this.totalEntradas = response.data.body.efetivadas.entradas;
          this.totalSaidas = response.data.body.efetivadas.saidas;
          //this.saldoCliente = response.data.body.saldo.saldoFormatado;
          this.somaDaPagina = response.data.body.efetivadas.somaPaginaFormatado;
          this.somaTotal = response.data.body.efetivadas.somaTotalFormatado;
          // this.panel = {...Array(this.totalDesserts).keys()].map((k, i) => i}
          if (this.totalDesserts == 0) {
            this.btnDownloadDisabled = true;
          } else {
            this.btnDownloadDisabled = false;
          }
          this.panel = [
            ...Array(response.data.body.efetivadas.count.__this).keys(),
          ].map((k, i) => i);
          Overlay.hide();
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 422) {
            this.errors = data.body.errors;
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          this.loadingDesserts = false;
          this.btnReloadLoading = false;
        });
    },
    downloadReport() {
      this.btnDownloadLoading = true;
      Overlay.show();

      Api.get("/v2/colaboradores/financeiro/export", {
        params: {
          startDate: this.startPeriod,
          endDate: this.endPeriod,
          entryType: this.selectedEntryTipoMovimentos.id,
          filterType: this.selectedEntryFiltrosMovimentos.id,
          page: this.page,
          export: this.toExport,
        },
        responseType: "arraybuffer",
      })
        .then((response) => {
          let blob = new Blob([response.data], {
            type: "application/vnd.ms-excel",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            "extrato_" + this.startPeriod + "-" + this.endPeriod + ".xlsx";
          link.click();
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 422) {
            this.errors = data.body.errors;
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
          this.btnDownloadLoading = false;
        });
    },
    getBalance() {
      this.loadingBalance = true;
      Api.get("/v2/colaboradores/financeiro/saldo")
        .then((response) => {
          this.saldoCliente = response.data.body.saldoFormatado;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 422) {
            this.errors = data.body.errors;
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          this.loadingBalance = false;
        });
    },
  },
});
