














































































































































































































































import Vue from "vue";
import Api from "@/services/Api";
import Auth from "@/lib/Auth";

import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import { Alert } from "@/lib/Alert";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import Swal from "sweetalert2";
import router from "@/router";
import { TableOptions } from "@/models/TableOptions";

export interface IbTransferencias {
  id?: number;
  data?: string;
  identificacao?: string;
  nome?: string;
  usuario?: string;
  cpfcnpj?: string;
  valor?: number;
  valorFormatado?: string;
}

export default Vue.extend({
  name: "PixPendentes",

  data() {
    return {
      txtAction: "Autorizar ",
      pinCodeProcessaPagtos: "",
      dialogPINProcessaPagtos: false,
      countSelecionados: "",
      btnLoadingCancela: false,
      btnLoadingAutoriza: false,
      btnLoading: false,
      pendentesPagarQtd: 0,
      pendentesPagarVlr: "0,00",
      saldoDisponivel: "0,00",
      singleSelect: false,
      buttonDisabled: true,
      selected: [],
      ModuloInfo: [],
      search: "",
      loadingDesserts: true,
      transferenciasPagar: [],
      transferenciasReceber: [],
      lang: navigator.language,
      errors: [],
      moment: moment,
      isMobile: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,
      options: {} as TableOptions,
      dadospagamentoPix: { id: 0 },
      headersPagar: [
        { text: "", value: "transacao_cod", sortable: false },
        {
          text: "DATA",
          value: "createdAt",
          sortable: false,
        },

        {
          text: "FAVORECIDO",
          value: "name",
          sortable: false,
        },
        {
          text: "CHAVE",
          value: "key",
          sortable: false,
        },

        {
          text: "IDENTIFICAÇÃO",
          value: "description",
          sortable: false,
        },

        {
          text: "VALOR",
          value: "amountFormatado",
          sortable: false,
          align: "right fw-bolder",
        },
      ],
      dessertsPagar: new Array<IbTransferencias>(),

      totalDessertsPagar: 0,
      timeout: 0,
      permissoes: { perm_pix_pendentes: false },
    };
  },
  created() {
    this.permissoes = Auth.getUserPermissoes();
    if (!this.permissoes.perm_pix_pendentes) {
      this.$router.go(-1);
    }
    this.isMobile = this.detectMobile();
  },

  methods: {
    closedialogPINProcessaPagtos() {
      this.pinCodeProcessaPagtos = "";
      this.txtAction = "Autorizar ";
      this.dialogPINProcessaPagtos = false;
    },
    focusPinProcessaPagtos() {
      this.$nextTick(() => {
        const wrapper = this.$refs.pincodeProcessaPagtos as any;

        window.setTimeout(() => wrapper?.focus(), 0);
      });
    },

    doDelayedSearch() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        //this is your existing function
        this.loadDatas();
      }, 1000);
    },
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },

    loadDatas() {
      Overlay.show();
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.loadingDesserts = true;
      Api.get("/v2/colaboradores/financeiro/pix/pendentes", {
        params: {
          page: page,
          limit: itemsPerPage,
        },
      })
        .then((response) => {
          this.dessertsPagar = response.data.body.pagar
            .rows as IbTransferencias[];
          this.totalDessertsPagar = Number(
            response.data.body.pagar.count.__total
          );
          this.pendentesPagarQtd = response.data.body.pagar.count.__total;

          this.pendentesPagarVlr = response.data.body.pagar.valorFormatado;
        })
        .finally(() => {
          Overlay.hide();
          this.loadingDesserts = false;
        });
    },
    showDialogProcessaPagamentos(action: string, item: any) {
      //console.log(item);

      this.dadospagamentoPix = item;
      this.txtAction = action;
      this.dialogPINProcessaPagtos = true;
      this.focusPinProcessaPagtos();
    },
    ProcessarPagamentos() {
      let url = "autorizaPendente";
      if (this.txtAction === "Cancelar ") {
        url = "cancelaPendente";
      }
      this.btnLoading = true;
      this.dialogPINProcessaPagtos = false;
      Overlay.show();

      this.loadingDesserts = true;
      Api.post("/v2/colaboradores/financeiro/pix/" + url, {
        transactionPin: this.pinCodeProcessaPagtos,
        idTransferencia: this.dadospagamentoPix.id,
      })
        .then((response) => {
          Swal.fire({
            text: response.data.body.message,
            icon: "success",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });
          this.selected = [];
          this.loadDatas();
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          Swal.fire({
            text: data.body.error,
            icon: "error",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });
        })
        .finally(() => {
          Overlay.hide();
          this.loadingDesserts = false;
          this.btnLoading = false;
        });
    },
    CancelaPagamentos() {
      for (let index = 0; index < this.selected.length; index++) {
        const element = this.selected[index]["id"];
        console.log(element);
      }
    },
    SomaPagamentos() {
      let total = 0 as number;
      for (let index = 0; index < this.selected.length; index++) {
        total += parseInt(this.selected[index]["valor"]);
      }
      console.log(total);
    },
  },

  watch: {
    options: {
      handler() {
        this.loadDatas();
      },
    },
    search: {
      handler() {
        this.doDelayedSearch();
      },
    },
  },
});
