var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"mx-auto",attrs:{"md":"12","sm":"12","cols":"12"}},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])}),_c('h3',{staticClass:"mb-3"},[_vm._v("Resumo de Movimentação")]),_c('v-card',{staticClass:"mt-3"},[_c('v-card-text',[_c('v-row',{staticClass:"mb-5"},[_c('v-col',{attrs:{"cols":"6","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"click":function($event){$event.preventDefault();}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data inicial","outlined":"","hide-details":"","color":"light-blue darken-4","type":"date","prepend-inner-icon":"mdi-calendar","dense":"","readonly":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuInitialDate),callback:function ($$v) {_vm.menuInitialDate=$$v},expression:"menuInitialDate"}},[_c('v-date-picker',{attrs:{"locale":"pt-BR"},on:{"input":function($event){_vm.menuInitialDate = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('v-col',{attrs:{"cols":"6","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"hint":"DD/MM/YYYY format","transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"type":"date","outlined":"","hide-details":"","dense":"","label":"Data final","prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFinalDate),callback:function ($$v) {_vm.menuFinalDate=$$v},expression:"menuFinalDate"}},[_c('v-date-picker',{attrs:{"locale":"pt-BR","max":_vm.maxDate},on:{"input":function($event){_vm.menuFinalDate = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"secondary","loading":_vm.loadingDesserts,"block":"","elevation":"0"},on:{"click":function($event){return _vm.doDelayedSearch()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-magnify")]),_vm._v(" Aplicar ")],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.desserts,"page":_vm.page,"sort-by":"createdAt","sort-desc":true,"items-per-page":_vm.itemsPerPage,"loading-text":"Carregando dados... Por favor aguarde","server-items-length":_vm.totalDesserts,"loading":_vm.loadingDesserts,"options":_vm.options,"footer-props":{
            itemsPerPageOptions: [999, 999],
            itemsPerPageText: 'Itens por página',
          }},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.valorTotal",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formataValor(item.valorTotal))+" ")]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }