import Vue from "vue";
import Auth from "@/lib/Auth";
import VueRouter, { RouteConfig } from "vue-router";
import Login from "@/views/account/Login.vue";
import Home from "@/views/Home.vue";
import RelatorioPix from "@/views/RelatorioPix.vue";
import RelatorioPixEnviados from "@/views/RelatorioPixEnviados.vue";
import MeuQRcode from "@/views/meuQrCode.vue";
import Movimento from "@/views/Movimento.vue";
import PixPendentes from "@/views/PixPendentes.vue";
import TransferenciasPixChave from "@/views/TransferenciasPixChave.vue";
import HomeRelatorios from "@/views/Relatorios/homeRelatorios.vue";
import ResumoAnual from "@/views/Relatorios/ResumoAnual.vue";
import ResumoMovimentacao from "@/views/Relatorios/ResumoMovimentacao.vue";
import Tst from "@/views/tst.vue";
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    meta: {
      auth: false,
    },
    component: () => import("@/layouts/Login.vue"),
    children: [
      {
        path: "/login",
        alias: "/login/",
        component: Login,
        meta: {
          title: "Acesse sua conta",
        },
      },
    ],
  },

  {
    path: "/",
    meta: {
      auth: true,
    },
    component: () => import("@/layouts/BaseApp.vue"),
    children: [
      {
        path: "",

        component: Home,
        meta: {
          title: "Resumo",
        },
      },
      {
        path: "/pix/meu-qr-code",

        component: MeuQRcode,
        meta: {
          title: "Meu QR Code",
        },
      },
      {
        path: "/pix/relatorios/emitidos",

        component: RelatorioPix,
        meta: {
          title: "Pix Recebidos",
        },
      },
      {
        path: "/pix/relatorios/enviados",

        component: RelatorioPixEnviados,
        meta: {
          title: "Pix Enviados",
        },
      },
      {
        path: "/movimento",

        component: Movimento,
        meta: {
          title: "Movimento",
        },
      },
      {
        path: "/pix/pendentes",

        component: PixPendentes,
        meta: {
          title: "Pix Pendentes",
        },
      },
      {
        path: "/pix/transferir/chave",

        component: TransferenciasPixChave,
        meta: {
          title: "Transferências Pix por chave",
        },
      },
      {
        path: "/relatorios",

        component: HomeRelatorios,
        meta: {
          title: "Relatórios",
        },
      },
      {
        path: "/relatorios/resumo/anual",

        component: ResumoAnual,
        meta: {
          title: "Resumo",
        },
      },
      {
        path: "/relatorios/resumo",

        component: ResumoMovimentacao,
        meta: {
          title: "Resumo de movimentação",
        },
      },
    ],
  },

  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0, behavior: "smooth" };
  },
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.auth)) {
    if (!Auth.isLogged()) {
      next({ path: "/login" });
    } else {
      next();
    }
  } else {
    next();
  }
});
router.afterEach((to, from) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + " - " + process.env.VUE_APP_NAME;
  }
});

export default router;
