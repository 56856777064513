







































































































































































































































































































































































































































































































































































































import Auth from "@/lib/Auth";
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import { Alert } from "@/lib/Alert";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import Swal from "sweetalert2";
import { TableOptions } from "@/models/TableOptions";

export interface RelatorioPix {
  data?: string;
  transactionId?: string;
  valorOriginal?: string;
  valorOriginalFormatado?: string;
  valorPago?: string;
  valorPagoFormatado?: string;
  status?: string;
  dataPagamento?: number;
  NomePagador?: string;
}

export default Vue.extend({
  name: "RelatorioPixEnviados",

  data() {
    return {
      hasLoadedData: false,
      dialogComprovantePixEnviado: false,
      dadosComprovantePixEnviado: [],
      menuInitialDate: false,
      menuFinalDate: false,
      menuInitialDateMobile: false,
      ModuloInfo: [],
      loadingData: false,
      search: "",
      loadingDesserts: false,
      lang: navigator.language,
      stats: [],
      errors: [],
      moment: moment,
      isMobile: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,
      options: {} as TableOptions,
      selectedEntryStatusPix: { id: "TODOS", descricao: "Todos" },
      selectedEntryOrigemPix: { id: "TODAS", descricao: "Todas" },
      origemPix: [
        { id: "TODAS", descricao: "Todas" },
        { id: "PIX_API", descricao: "Mod API" },
        { id: "TRANSFER", descricao: "Mod Pagamentos" },
        { id: "QRCODE", descricao: "Mod QRCode" },
      ],
      statusPix: [
        { id: "TODOS", descricao: "Todos" },
        { id: "CONFIRMADA", descricao: "Confirmados" },
        { id: "PENDENTE", descricao: "Em processamento" },
        { id: "PEND_CONFIRM", descricao: "Pendente confirmação" },
        { id: "CANCELED", descricao: "Cancelados" },
        { id: "ERRO", descricao: "Com erros" },
        { id: "DEVOLVIDA", descricao: "Devolvidas" },
      ],
      headers: [
        { text: "", value: "status", sortable: false },
        { text: "Data", value: "data", sortable: false },
        {
          text: "ID / External Id",
          value: "transactionId",
          sortable: false,
        },
        { text: "Favorecido / Chave", value: "nomeDestino", sortable: false },
        {
          text: "Valor",
          value: "valorOriginalFormatado",
          sortable: false,
          align: "right",
        },
        {
          text: "",
          value: "valor",
          sortable: false,
        },
      ],
      desserts: new Array<RelatorioPix>(),

      totalDesserts: 0,
      timeout: 0,
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      defaultStartDate: moment().format("YYYY-MM-DD"),
      defaultEndDate: moment().format("YYYY-MM-DD"),
      startPeriod: moment().add(-1, "hours").format("YYYY-MM-DDTHH:mm"),
      endPeriod: moment().format("YYYY-MM-DDT23:59"),
      maxDate: moment().format("YYYY-MM-DD"),
      permissoes: {
        perm_pix_recebidos: false,
        perm_pix_enviados: false,
        perm_qrcode_static: false,
      },
      btnDownloadDisabled: true,
      btnDownloadLoading: false,
    };
  },
  created() {
    this.permissoes = Auth.getUserPermissoes();
    if (!this.permissoes.perm_pix_enviados) {
      this.$router.go(-1);
    }
    this.isMobile = this.detectMobile();
  },

  methods: {
    next(page: number) {
      this.page = page;
      this.loadDatas();
    },
    doDelayedSearch() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        //this is your existing function
        this.loadDatas();
      }, 1000);
    },
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    downloadReport() {
      const intervalo = Math.round(
        (new Date(this.endPeriod).getTime() -
          new Date(this.startPeriod).getTime()) /
          (1000 * 3600 * 24)
      );

      if (intervalo > 31) {
        Snackbar.show(
          "O intervalo de pesquisa não pode ser superior a 31 dias",
          "error"
        );
        return false;
      }
      this.btnDownloadLoading = true;
      Overlay.show();

      Api.get("/v2/colaboradores/financeiro/pix/relatorios/enviados", {
        params: {
          startDate: this.startPeriod,
          endDate: this.endPeriod,
          query: this.search,
          status: this.selectedEntryStatusPix.id,
          origem: this.selectedEntryOrigemPix.id,
          export: true,
        },
        responseType: "arraybuffer",
      })
        .then((response) => {
          let blob = new Blob([response.data], {
            type: "application/vnd.ms-excel",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            "pix_enviados_" +
            this.selectedEntryStatusPix.id +
            "-" +
            this.startPeriod +
            "-" +
            this.endPeriod +
            ".xlsx";
          link.click();
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 422) {
            this.errors = data.body.errors;
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
          this.btnDownloadLoading = false;
        });
    },
    loadDatas() {
      const intervalo = Math.round(
        (new Date(this.endPeriod).getTime() -
          new Date(this.startPeriod).getTime()) /
          (1000 * 3600 * 24)
      );

      if (intervalo > 31) {
        Snackbar.show(
          "O intervalo de pesquisa não pode ser superior a 31 dias",
          "error"
        );
        return false;
      }
      this.hasLoadedData = true;
      Overlay.show();

      this.loadingData = true;

      this.loadingDesserts = true;
      Api.get("/v2/colaboradores/financeiro/pix/relatorios/enviados", {
        params: {
          startDate: this.startPeriod,
          endDate: this.endPeriod,
          query: this.search,
          status: this.selectedEntryStatusPix.id,
          origem: this.selectedEntryOrigemPix.id,
          page: this.page,
        },
      })
        .then((response) => {
          this.desserts = response.data.body.rows as RelatorioPix[];
          this.totalDesserts = Number(response.data.body.count.__total);
          this.stats = response.data.body.stats;
          this.loadingDesserts = false;
          this.loadingData = false;
          if (this.totalDesserts == 0) {
            this.btnDownloadDisabled = true;
          } else {
            this.btnDownloadDisabled = false;
          }
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            //exe
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    Comprovante(id: string) {
      Api.get("/v2/colaboradores/comprovantes/pix/sent/" + id)
        .then((response) => {
          this.dadosComprovantePixEnviado = response.data.body;
          this.dialogComprovantePixEnviado = true;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;
          Snackbar.show(data.body.error, "error");
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    rowClasses(item: any) {
      const ToDate = new Date();
      if (item.status === "PENDENTE") {
        return "";
      }
      if (item.status === "CONFIRMADA") {
        return "success--text";
      }
      if (item.status === "PEND_CONFIRM") {
        return "warning--text";
      }
      if (item.status === "DEVOLVIDA") {
        return "error--text";
      }
      if (item.status === "ERRO") {
        return "error--text";
      }
    },
  },
});
