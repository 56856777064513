import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueCompositionAPI from "@vue/composition-api";
import { Overlay } from "@/lib/Overlay";
import "@/assets/styles/styles.css";
import Swal from "sweetalert2";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { VueMaskDirective } from "v-mask";
Vue.directive("mask", VueMaskDirective);
const firebaseConfig = {
  apiKey: "AIzaSyCE4faM0vfn1MnUVZQxVuHCf280d0G1EuA",
  authDomain: "ezzebank.firebaseapp.com",
  projectId: "ezzebank",
  storageBucket: "ezzebank.appspot.com",
  messagingSenderId: "580937014992",
  appId: "1:580937014992:web:ad27e88871cb76427008d5",
  measurementId: "G-FWXVJPK03C",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

Vue.use(VueCompositionAPI, Swal);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
