





































































































































































import Vue from "vue";
import Auth from "@/lib/Auth";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import Swal from "sweetalert2";
export default Vue.extend({
  name: "Dashboard",

  data() {
    return {
      ModuloInfo: [],
      loadingData: true,
      dadosUsuario: {},
      lang: navigator.language,
      errors: [],
      rand: 1,
      moment: moment,
      isMobile: false,
      permissoes: {},
    };
  },
  created() {
    this.isMobile = this.detectMobile();
    this.rand = new Date().getTime();
    this.dadosUsuario = Auth.getUserData();
    this.permissoes = Auth.getUserPermissoes();
  },

  methods: {
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
  },
});
